.InputData {
	display: flex;
}

.sourceVoiceColumn {
	position: relative;
}

.voiceFixerCheckBox {
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
}

/* 
###Generel stile###
*/

.titelField {
	text-align: center;
	font-size: 20px;
}