.DisplayVoice {
	font-size: 16px;
	display: flex;
	align-items: center;
}

.playButton {
	display: flex;
	flex: 1;
	justify-content: end;
}