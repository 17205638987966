.PlayAudio {
	display: inline-flex;
	flex: 1;
	justify-content: end;
}

.progressBar {
	width: 150px;
	display: flex;
	align-items: center;
}