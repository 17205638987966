.inputTextField {
	box-sizing: border-box;
	height: 300px;
	width: 400px;
	padding: 0;
	outline: none;
	resize: none;
	padding: 3px;
	font-size: 14px;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
	background-color: #edeef6;
	border-radius: 5px;
	caret-color: #979797;
}

/*.inputTextField:focus {
	border-color: #1565c0;
}*/