body {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: #edeef6;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.App {
	background-color: #d9dcee;
	padding: 10px;
	border-radius: 15px;
}

.generateButtonContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 5px;
}

.errorMessage {
	font-size: 10px;
	color: red;
}