.Result {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.generatedAudio {
	margin-top: 20px;
	width: 400px;
	font-size: 20px;
}

.title {
	text-align: center;
}

.resultItem {
	margin-top: 5px;
	display: flex;
	align-items: center;
}

.fieldName {
	display: inline;
	width: 200px;
}